<template>
  <div style="position:relative; display: flex; flex-direction: column; height: 100%"
       :class="dialog ? 'pt-6 pb-0' : ''"
  >
    <v-card v-if="item" style="flex: 0" class="vote-description">
      <v-card-title class="pt-2 pb-1 px-4">
        {{ item.theme }}
      </v-card-title>
      <v-card-text class="pt-0 pb-1 px-4">
        <v-chip label small class="mr-2">{{ item.create_date | formatDate }}</v-chip>
        <v-chip label small class="mr-2">{{ item.owner_name }}</v-chip>
        <v-chip label small color="error lighten-1" v-if="item.closed">Завершено</v-chip>
      </v-card-text>
      <v-card-text class="pb-0 pt-2 px-4" v-if="item.vote_type !== 'POLL'">
        <p style="font-size: .94rem; margin-bottom: 4px"
           class="grey--text text--darken-2"
           :style="item.vote_type === 'INFORMING' ? 'margin-bottom: 12px; font-size: 1rem;' : ''"
        >
          {{ item.text }}
        </p>
      </v-card-text>
      <v-card-text class="pt-0 px-4" v-if="(item.options || []).length">
        <div
            class="answer-line"
            style="padding: 5px 0;"
            v-for="(question, idx) in item.options" :key="idx"
        >
          <div class="answer-stat" style="display: flex" v-if="item.vote_type === 'POLL'">
            <div class="question" style="flex: 1">
              {{ question.title }}
            </div>
            <div class="votes" style="flex: 0 0 80px; text-align: right">
              {{ question.votes }} голосів
            </div>
          </div>
          <div class="answer-progress" style="display: flex; flex-wrap: nowrap;">
            <v-progress-linear
                :value="getPercent(question)"
                :color="getQuestionColor(question.positive)"
                striped
                height="20"
            >
              <template v-slot:default>
                <span
                    style="position: absolute; left: 10px; font-weight: 500"
                    v-if="item.vote_type === 'VOTING'"
                >{{ question.title }}</span>
                <strong v-if="item.vote_type === 'VOTING'">{{ getPercent(question) }}% ({{question.votes}} голосів)</strong>
                <strong v-else>{{ getPercent(question) }}%</strong>
              </template>
            </v-progress-linear>
            <v-btn depressed x-small tile
                   :color="item.closed ? 'error' : 'grey darken-1'"
                   :dark="!item.closed"
                   style="flex: 0 0 40px"
                   :disabled="item.closed"
                   @click="doVote(question.id)"
            >
              <v-icon size="19">mdi-hand-pointing-left</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="answer-line" style="padding: 6px 0;">
          <div class="answer-stat" style="display: flex" v-if="item.vote_type === 'POLL'">
            <div class="question" style="flex: 1">
              Не проголосувало
            </div>
          </div>
          <div class="answer-progress">
            <v-progress-linear
                :value="getNotVote('percent')"
                color="grey lighten-2"
                striped
                height="20"
            >
              <template v-slot:default>
                <strong>{{ getNotVote('percent') }}% ({{ getNotVote('votes') }} голосів)</strong>
              </template>
            </v-progress-linear>
          </div>
        </div>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
    <template v-if="item">
      <v-card class="mt-5 mb-2 grey lighten-3" tile elevation="0" style="flex: 1; overflow-y: auto; overflow-x: hidden" id="chat">
        <v-row id="chat-body">
          <template v-for="(item, idx) in items">
            <v-col cols="3" :key="`${idx}-s`" v-if="item.self" style="">
              <div style="font-size: .8rem; text-align: right">{{ item.create_date | formatDate }}</div>
            </v-col>
            <v-col cols="9" class="pb-1"
                   :key="idx"
                   :style="item.self ? 'padding-left: 2px' : 'padding-right: 2px'"
            >
              <v-card
                  :shaped="!item.self"
                  :class="item.self ? 'grey lighten-2 pt-1 px-2 pb-2' : 'grey lighten-5 pt-3 px-2 pb-2'"
                  style="position: relative"
              >
                <div v-if="!item.self">
                  <v-chip small
                          label
                          :color="item.position ? 'secondary lighten-1' : 'grey'"
                          dark
                          class="py-1"
                          style="position: absolute; top: -2px; left: -1px;">
                    {{ item.position ? item.position : item.address }}
                  </v-chip>
                </div>
                <div
                    :style="item.self ? 'margin-top: 2px' : 'margin-top: 13px'"
                    style="font-size: .85rem"
                >
                  {{ item.text }}
                </div>
              </v-card>
            </v-col>
            <v-col cols="3" :key="`${idx}-s`" v-if="!item.self">
              <div style="font-size: .8rem; text-align: left">{{ item.create_date | formatDate }}</div>
            </v-col>
          </template>
        </v-row>
      </v-card>
      <v-card style="flex: 0" class="mb-2">
        <div style="display: flex; align-items: center">
          <div style="flex: 1">
            <v-text-field single-line rounded hide-details v-model="message" placeholder="Введіть повідомлення"
                          class="pt-0"
                          @keypress.enter="addComment"
            />
          </div>
          <div style="flex: 0 0 39px">
            <v-btn icon small :disabled="!message" @click="addComment">
              <v-icon>mdi-send</v-icon>
            </v-btn>
          </div>
        </div>
      </v-card>
    </template>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {VOTE_ADD, VOTE_COMMENT_CREATE, VOTE_COMMENT_GET_ALL, VOTE_UPDATE_OPTION} from "@/store/actions/vote";

export default {
  name: "VoteItemDescription",
  props: {
    item: {
      type: Object,
      default() {
        return {
          theme: '',
          text: '',
          vote_positive: 0,
          vote_negative: 0,
          flats: 0,
        }
      },
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      items: 'getComments',
      socket_data: 'get_socket',
    })
  },
  data() {
    return {
      message: '',
    }
  },
  methods: {
    ...mapActions(
        {
          vote_add: VOTE_ADD,
          vote_comment_fetch: VOTE_COMMENT_GET_ALL,
        }
    ),
    getQuestionColor(positive) {
      if (this.item.vote_type === "VOTING") {
        return positive ? 'light-blue lighten-1' : 'error lighten-3'
      } else {
        return 'light-blue lighten-1'
      }
    },
    doVote(question_id) {
      if (this.item.closed) return
      this.vote_add(question_id)
        .then(response => {
          if (response) {
            this.$ws.send_message(Object.assign(response, { ws_type: 'vote_do_vote' }))
          }
        })
      .catch(() => {
        console.log('eeee')
      })
    },
    getPercent(question) {
        return (question.voters ? (question.votes / question.voters) * 100 : 0).toFixed(2)
    },
    getNotVote(type) {
      const total_votes = this.item.options.reduce((acc, item) => acc + (item.votes || 0),0)
      const first = this.item.options[0]

      if (type === 'votes') {
        return (first.voters ? first.voters - total_votes : 0).toFixed(0)
      }

      return (first.voters ? (first.voters - total_votes) / first.voters * 100 : 0).toFixed(2)
    },
    addComment() {
      if (!this.message) return ''

      this.$store.dispatch(VOTE_COMMENT_CREATE, { vote_id: this.item.id, text: this.message })
        .then(response => {
          if (response) {
            try {
              this.$ws.send_message(Object.assign(response, { ws_type: 'vote_comment' }))
              this.message = ''
            } catch {
              this.message = ''
            }
            this.autoScroll()
          }
        })
    },
    autoScroll() {
      const chat = document.getElementById('chat')
      if (chat) {
        const scroll = chat.scrollHeight
        const height = chat.clientHeight

        if (scroll >= height) {
          chat.scrollTo(0, scroll);
        }
      }
    }
  },
  watch: {
    item: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.vote_comment_fetch(payload.id)
            .then(response => {
              if (response) {
                this.autoScroll()
              }
            })
        }
      }
    },
    socket_data: {
      immediate: true,
      handler(payload) {
        if (payload && this.item) {
          if (payload.data.ws_type === 'vote_comment'
              && payload.data.vote_id === this.item.id
          ) {
            if (!this.items.find(item => item.id === payload.data.id)) {
              let local_payload = Object.assign({}, payload.data, {self: false})
              this.$store.commit(VOTE_COMMENT_CREATE, local_payload)
              this.autoScroll()
            }
          }

          if (payload.data.ws_type === 'vote_do_vote') {
            this.$store.commit(VOTE_UPDATE_OPTION, payload.data)
          }
        }
      }
    },
  }
}
</script>

<style scoped>

</style>